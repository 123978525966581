.delivery__outta {
  margin: 11.2rem 0 0 0;
  padding: 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow-x: hidden;
}

.delivery_header {
  width: 100%;
  height: 39rem;
  background-image: url(./FooterAsset/delivery.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.delivery__outta h1 {
  padding: 20px 0;

  font-size: 4.5rem;
  word-spacing: 0.2px;
  text-shadow: 0px 2px 2.5px rgba(0, 0, 0, 0.65);
}
.delivery__outta_secondary {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.delivery__outta_secondary h2 {
  padding: 2rem .5rem;
}
.delivery__outta_secondary h4 {
  padding: 0 .5rem;
}

.delivery__content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 5rem 0;
}
.delivery_detail h4 {
  font-size: 2rem;
  text-shadow: 1px 2px 2px rgba(7, 5, 5, 0.5);
}

.delivery_detail p {
  font-size: 1.3rem;
  text-align: left;
}
.delivery_img {
  width: auto;
  height: 13rem;
  margin: 1.5rem 0;
  border-radius: 50%;
  border: .1rem solid #bdc3c7;
  box-shadow: 2px 6px 7px 1px rgba(0, 0, 0, 0.31);
}
.delivery__content_secondary p {
  font-size: 1.8rem;
  font-weight: 700;
}
.delivery__content_secondary span {
  text-decoration: underline;
}
.delivery__content_secondary small {
  text-decoration: underline;
  font-size: small;
  font-style: italic;
}
.delivery_text_bottom {
  padding: 5rem;
}
.delivery_text_bottom p {
  font-size: 3.5rem;
  text-shadow: 0px 2.5px 1.5px rgb(0 0 0);
  color: #F36127;
}
