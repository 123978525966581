.orderScreen__div {
  margin: 10rem;
}
.orderScreen_placeorder {
  display: flex;
  flex-grow: wrap;
  padding: 1rem;
  justify-content: space-between;
  font-size: 1.6rem;
}
.orderScreen_placeorder-info {
  flex: 3 1 60rem;
}

.orderScreen_placeorder-info > div {
  border: 0.1rem #c0c0c0;
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 1rem;
  margin: 1rem;
}
.orderScreen_placeorder-info > div :first-child {
  margin-top: 0;
}
.orderScreen_placeorder-action {
  border: 0.1rem #c0c0c0;
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 2rem 3rem;
  font-size: 2rem;
}

.orderScreen_placeorder-action > ul {
  padding: 0;
  list-style-type: none;
}
.orderScreen_placeorder-action > ul > li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.orderScreen_placeorder-action > ul > li:last-child {
  font-size: 2rem;
  color: #c04000;
  font-weight: bold;
}
.orderScreen_placeorder-actions-payment > div {
  width: 100%;
}
.card-img-top {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.orderScreen_textarea {
  width: 100%;
}
.orderScreen_map {
  height: 70vh;
  width: 100%;
}