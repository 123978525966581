@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
html {
  font-size: 62.5%; /* 16px * 62.5 = 10px = 1rem */
  min-width: 375px;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Helvetica",-apple-system, BlinkMacSystemFont; */
}
html > body {
  /* background-color: #fafafa; */
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.7;
  overflow-x: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  font-family: FuturaBT-MediumCondensed;
  /* font-family: "Helvetica"; */
  /* font-size: normal;
  font-weight: normal; */
}

/* Error_div apply to div of Loading.  */
.Error_div {
  display: flex;
  text-align: center;
  width: 100%;
  font-size: 4rem;
  justify-content: center;
  margin: 5rem;
}
/* when loading below icon will spinning */
.spinning_icon {
  -webkit-animation: rotation 0.5s infinite linear;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
/* end of icon rotate */
button {
  font-size: 1.6rem;
}

.button {
  text-transform: uppercase;
  text-decoration: none;
  background: #2979ff;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  width: 100%;
}

.full-width {
  width: 100%;
  color: #ffffff;
}
#root {
  height: 100%;
  overflow-x: hidden;
}

/* Reviews */
ul.review {
  list-style-type: none;
  padding: 0;
}
.review li {
  margin: 1rem;
  margin-bottom: 2rem;
}
.review li > div {
  margin: 0.5rem;
}

/* Rating */
.rating span {
  color: #ffb901;
  font-size: 1.8rem;
  margin: 0.1rem;
}
.rating span:last-child {
  color: #444444;
  font-size: 1.4rem;
}
a > .rating > span:last-child {
  color: #0000ff;
}

/* Code below use in  CartScreen.js ,
PlaceOrderScreen.js,
OrderScreen.js*/

.productPicked__div {
  margin: 50px 0;
}
.productPicked__div ul {
  margin: 0;
  padding: 0 20px;
}
.cart-list-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.card {
  z-index: 0;
}
.cart-list-container .card {
  margin: 10px;
  list-style: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 150px;
  height: auto;
  border: 1px solid #f0f0f0;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.35);
}
.card-title {
  font-size: 2rem;
  margin: 0 0 0 0;
}
div .cartDetail_choices {
  font-size: 2rem;
  padding: 0;
}
.cartDetail_choices button {
  margin-top: 1.5rem;
  font-size: 1.9rem;
}
.card .img-thumbnail {
  border: none;
}
.cart-price {
  flex: 1 1;
  font-size: 2.5rem;
  text-align: right;
}

/* end of CartScreen.js */

/* start of PaymentScreen.js 
*/

/*forms -- payment form */
div .form {
  border-top: none;
}
.form {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 1rem;
  height: 100%;
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 32rem;
  padding: 2rem;
  border-radius: 0.5rem;
  list-style: none;
}
.form-container li {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
}
input {
  margin: 10px 0 10px 0;
  padding: 5px;
  border: 1px solid grey;
  border-radius: 0.5rem;
}

/* start ProductionScreen.js */
/* content-margined also work in OrderScreen.js */
.content-margined {
  margin: 11.2rem 0 0 0;
  display: flex;
  flex-direction: column;
  /* overflow-x: hidden; */
}

.product-header {
  width: inherit;
  margin: 0 20px;

  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.product-header h3,
.product-header button {
  margin: 0 0 50px 0;
}
.product-header .btn {
  background-color: #ff8000;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  width: 250px;
  height: 40px;
  font-size: 18px;
  color: white;
  margin: 50px 0;
}
.product-header .btn:active,
.product-header .btn:hover {
  background-color: #113067;
  color: #ffffff;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.35);
}
.CreateProduct_form-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  border-top: none;
}

/* ProductsScreen.js start from class: product-list_div__Content */
.table {
  /* width: initial; */
  font-size: 1.7rem;
  overflow-x: scroll;
}
th {
  text-align: left;
}
tbody > tr:nth-child(odd) {
  background-color: #f0f0f0;
}
td button {
  margin: 10px 0;
}
.product-list_div__Content {
  overflow-x: auto;
};
/* end of ProductsScreen.js 
I guess...
 */

/*start from checkoutsteps.js  */
.payment_outta {
  margin: 8rem 0 0 0;
  font-family: "Helvetica";
}
.checkout-steps {
  display: flex;
  width: 70%;
  margin: 1rem auto;
  font-size: 2rem;
  text-align: center;
}
.checkout-steps > div {
  border-bottom: 0.3rem #bcbcbc dashed;
  color: #bcbcbc;
  flex: 1 1;
  padding-top: 1rem;
}
.checkout-steps > div.active {
  border-bottom: 0.3rem #ef6c00 solid;
  color: #ef6c00;
}
/*end of checkoutsteps.js  */

/* start of OrderScreen.js */

/* .productPicked__div .orderScreen_ShoppingcartDiv {
  margin: 10px;
} */
.placeorder-action h5 {
  text-align: center;
  font-size: 1.5rem;
}
/* start PlaceOrderScreen.js 
from .placeorder
*/

/* Start ProfileScreen.js
 */
.profile {
  margin: 11.2rem 0 0 0;
  display: flex;
  flex-wrap: wrap;
}
.profile-info {
  flex: 1 1 30rem;
}
.profile-orders {
  flex: 3 1 60rem;
}

/* admin */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  padding: 1rem;
  list-style: null;
  z-index: 1;
  margin: 0;
  margin-top: 0.4rem;
  width: 100%;
  background: #fafbfc;
}
.dropdown:hover .dropdown-content {
  display: block;
}

.categories {
  padding: 0;

  display: flex;
  list-style: none;
}

.categories a:hover {
  background-color: #c0c0c0;
  padding: 1rem;
}

/* verify phone */
.forgot_verify-phone,
.verify-sms {
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 18rem auto;
}

.verify-phone,
.verify-sms {
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 18rem auto;
}
/* shipping */
.shipping_address {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.shipping {
  display: inline-block;
  vertical-align: middle;
  padding: 1rem;
  text-align: left;
}

/* start OrderScreen.js */
.order-header h3 {
  font-size: 3rem;
}
div .order_btn__main {
  background-color: #f36127;
}

td > .order_btn__secondary {
  background-color:white;
  border: 0rem ;
  color: #000000;
}
td > .order_btn__secondary:hover, td > .order_btn__secondary:active {
  background-color: black;
  color: white;
}

/* register screen */
.register-screen {
  margin: 11.1rem 0;
  font-family: "Helvetica";
}
.signin-screen {
  margin: 98px 0;
  font-family: "Helvetica";
}
ul > li > .signInBtn {
  font-size: 2rem;
  padding: 2rem;
  border-radius: 0.5rem;
}
ul > li > .signInBtn:active,
ul > li > .signInBtn:hover {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
}
/* Coupon Admin Screen */
.CreateCoupon_form-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  border-top: none;
}
.coupon-list_div__Content {
  width: inherit;
  margin: 0 20px;

  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.coupon-list_li input {
  width: 100%;
}
/* Orders Screen */
.order-list {
  overflow-x: auto;
}
.Banner_slider_outta {
  margin: 8.5rem 0 0 0;
  height: 50rem;
  background-image: url(/static/media/OKcover.0efd9f6d.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-width: 37.5rem;
}
.BG_Overlay {
  width: 100%;
  min-width: 37.5rem;
  height: 50rem;
  background-color: rgba(255, 255, 255, 0.5);
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.BG_Overlay > p > span {
  display: block;
}
.BG_Overlay > p > span:first-child {
  font-size: 7rem;
  font-weight: 900;
}
.BG_Overlay > p > span:nth-child(2),
.BG_Overlay > p > :nth-child(3) {
  font-size: 3.5rem;
  font-weight: 400;
}

.banner_btn {
  display: flex;
  width: inherit;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.Ban_btnE {
  text-align: center;
  display: flex;
  justify-content: center;
  width: auto;
  padding: 1rem;
  border-radius: .5rem;
  color: #fff;
  background-color: #31525b;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 2rem;
  margin: 1rem;
}
.btn-float {
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
 .btn-float:hover,
 .btn-float:active,
 .btn-float:focus {
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.12);
  font-weight: bold;
  transform: scale(1.1);
  color: #ffa101;
  text-decoration: none;
  transform: translateY(-8px);
}
@media all and (max-width: 1250px) {
  .BG_Overlay > p > span:first-child {
    font-size: 5rem;
    font-weight: 900;
  }
  .BG_Overlay > p > span:nth-child(2),
  .BG_Overlay > p > :nth-child(3) {
    font-size: 2.8rem;
    font-weight: 400;
  }
}

/* /* .Banner_imgItem{
    display: flex;
    width: 100%;
    setting a fixed height for this div allows for flex scaling
    height: 55rem;
    height: 100%;
    background: greenyellow;
    overflow: hidden;
}
.Banner_img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    margin: auto;
}
.control-dots{
    display: flex;
    justify-content: flex-end;
}
@media all and (max-width: 850px){
    .Banner_imgItem{
        height: initial;
        width: auto;
    }
    .Banner_img{
        width: auto;
        height: initial;
    }
} */

.ourMenu_outta {
  margin: 2rem 1rem 0 1rem;
}
.ourMenu_content {
  display: flex;
  justify-content: center;
}
.ourMenu_content > h2 {
  font-size: 3rem;
  font-weight: bolder;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 5rem;
  margin: 0;
  align-items: center;
  background: #E7EDF3;
  border-radius: .5rem;
  color: #ffa101;
}

.ourMenu_Carousel {
  margin: 0 3rem;
}
div .ourMenu_Carousel > ul {
  width: 100%;
  margin: 2rem 0;
  justify-content: center;
}
li .menu_item {
  border-radius: 1rem;
  border: 0rem;
}
div .ourMenu_Carousel > ul > .ourMenu_itemEach {
  max-width: 30rem;
  margin: 0 0.5rem;
  border-radius: 1rem;
}
.ourMenu_itemEach::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.38),
    3px 5px 7px 0px rgba(0, 0, 0, 0.2);
  z-index: -1;
  opacity: 0;
  transform: scale(0.75);
  transition: all 0.2s ease-in-out;
  will-change: transform;
  border-radius: 1rem;
}
.ourMenu_itemEach:hover {
  opacity: 1;
  transform: none;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

.pfl_ourMenu {
  height: 35vh;
}
.pflimage_ourMenu {
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 1rem;
}
.pflhover_ourMenu {
  width:100%;
  height: 100%;

  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.pfl_ourMenu:hover .pflimage_ourMenu {
  opacity: 0.7;
}
.pfl_ourMenu:hover:before,
.pflhover_ourMenu:hover {
  opacity: 1;
}
div .pflhover_ourMenu .pflhover_each_type_btn {
  color: white;
  background-color: black;
  font-size: 4.2rem;
  font-weight: bold;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.card-body {
  display: none;
}

.card-body .ourMenu_each_type_btn:hover {
  color: #31525b;
  font-weight: 900;
  /* text-shadow: 6px 6px 0px rgba(0,0,0,0.2); */
}
.ourMenu_each_type_btn {
  width: 22rem;
  height: 4rem;
  font-size: 2.25rem;
  line-height: 1.2;
  color: #31525b;
  font-weight: 900;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

/* carousel arrow  */
.react-multiple-carousel__arrow {
  z-index: 0;
}

@media all and (max-width: 1290px) {
  .pfl_ourMenu {
    height: inherit;
  }
  .pflimage_ourMenu {
    border-radius: 1rem;
    width: -webkit-fill-available;
    height: 19rem;
  }

  .pfl_ourMenu:hover .pflimage_ourMenu {
    opacity: 0.95;
  }
  .pfl_ourMenu:hover .pflhover_ourMenu {
    opacity: 1;
  }

  div .ourMenu_Carousel > ul {
    width: unset;
  }
}
@media all and (min-width: 1024px) {
  .ourMenu_Carousel > ul > li > .menu_item > .body_ourMenu {
    display: none;
  }
  .ourMenu_Carousel > ul > li > .menu_item > .body_HomeScreen {
  display: none;
  
}

}
@media all and (max-width:1023px){
  .pflhover_ourMenu  {
  
    display: none;
  }
}
@media all and (max-width: 860px) {
  .pflimage_ourMenu {
    border-radius: 1rem;
    width: -webkit-fill-available;
    height: 19rem;
  }
  .pflhover_ourMenu {
    display: none;
  }
  .ourMenu_Carousel > ul > li > .menu_item > .body_ourMenu {
    display: flex;
  }
}
@media all and (max-width: 730px) {
  .pflimage_ourMenu {
    border-radius: 1rem;
    width: -webkit-fill-available;
    height: 20rem;
  }
  .ourMenu_content > h2 {
    font-size: 2rem;
    font-weight: bolder;
    width: 80%;}
}
@media all and (max-width: 560px) {
  .pflimage_ourMenu {
    border-radius: 1rem;
    width: -webkit-fill-available;
    height: 15rem;
  }
}
@media all and (max-width: 471px) {
  div .ourMenu_Carousel > ul {
    width: unset;
  }
  .card-body {
    padding: 0 1rem;
  }
  .card-body .ourMenu_each_type_btn {
    width: 22rem;
    height: 4rem;
    font-size: 1.9rem;
    line-height: 2.2;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }
  .pflimage_ourMenu {
    border-radius: 1rem;
    width: -webkit-fill-available;
    height: 12rem;
  }
}

.specialMenu_outta {
  margin: 0 1rem;
}
.specialMenu_content {
  display: flex;
  justify-content: center;
}
.specialMenu_content > h2 {
  font-size: 3rem;
  font-weight: bolder;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 5rem;
  margin: 0;
  align-items: center;
  background: #E7EDF3;
  border-radius: .5rem;
  color: #ffa101;
}

.specialMenu_Carousel {
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0 1.5rem;
}
.showLink_mobile {
  /* display: none; */
}
div .specialMenu_Carousel > ul {
  /* width: 100%; */
  margin: 2rem 0;
  justify-content: center;
}
li .specialmenu_item {
  border-radius: 1rem;
  border: 0rem;
}
div .specialMenu_Carousel > ul > .specialMenu_itemEach {
  max-width: 30rem;
  margin: 0 0.5rem;
  border-radius: 1rem;
}
.specialMenu_itemEach::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.38),
    3px 5px 7px 0px rgba(0, 0, 0, 0.2); */
  z-index: -1;
  opacity: 0;
  transform: scale(0.75);
  transition: all 0.2s ease-in-out;
  will-change: transform;
  border-radius: 1rem;
}
.specialMenu_itemEach:hover:before {
  opacity: 1;
  transform: none;
}

.pfl_special {
  height: 35vh;
}
.pflimage_special {
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 1rem;
}
.pflhover_special {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: #fae6b1;
  width: 90%;
  height: auto;
  /* text-align: center; */
  background-color: wheat;
  border-radius: 1rem;
}
.pfl_special:hover .pflimage_special {
  opacity: 0.89;
  box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.38),
    3px 5px 7px 0px rgba(0, 0, 0, 0.2);
}
.pfl_special:hover .pflhover_special {
  opacity: 1;
  box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.38),
    3px 5px 7px 0px rgba(0, 0, 0, 0.2);
}
div .pflhover_special .pflhover_special_btn {
  color: #344648;
  /* background-color: black; */
  font-size: 2.2rem;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  opacity: 0.8;
  font-weight: 800;
  display: flex;
  justify-content: center;
}

.card-body {
  display: none;
}

@media all and (max-width: 1290px) {
  .pfl_special {
    /* height: 23vh; */
  }
  .pflimage_special {
    opacity: 1;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 1rem;
  }

  .pfl_special:hover .pflimage_special {
    opacity: 0.95;
  }
  .pfl_special:hover .pflhover_special {
    opacity: 1;
  }
  .card-body {
    display: flex;
    padding: none;
  }
  .card-body .specalMenu_each_type_btn {
    width: 22rem;
    height: 4rem;
    font-size: 2.25rem;
    line-height: 1.2;

    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }
  div .specialMenu_Carousel > ul {
    width: unset;
  }
}
@media all and (max-width:730px){
  .specialMenu_content > h2 {
    font-size: 2rem;
    font-weight: bolder;
    width: 80%;}
}

@media all and (max-width: 471px) {
  div .specialMenu_Carousel > ul {
    width: unset;
  }
  .card-body {
    padding: 0 1rem;
  }
  .card-body .speicalMenu_each_type_btn {
    width: 22rem;
    height: 4rem;
    font-size: 1.9rem;
    line-height: 2.2;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }
  div .specialMenu_Carousel > ul > .specialMenu_itemEach {
    max-width: unset;
    margin: 3rem;
  }
  .pflimage_special {
    width: 16rem;
    height: 16rem;
  }
  div .specialMenu_Carousel > ul {
    margin: 0;
  }
  .pfl_special {
    height: 18vh;
  }
  .pflhover_special {
    left: 58%;
}
}
.whyUs__outta {
  margin: 4rem 2.5rem;
  display: flex;
  flex-direction: column;
  font-family: "Helvetica", -apple-system, BlinkMacSystemFont;
}
.whyUs__title {
  text-align: center;
  margin: 1rem;
}
.whyUs__title > h2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.whyUs__heading_main {
  display: flex;
  margin-bottom: 2rem;
  text-transform: uppercase;
  width: 100%;
  justify-content: center;
  align-self: center;
  background: #E7EDF3;
  border-radius: .5rem;
  color: #ffa101;
  font-size: 3rem;
  font-weight: bolder;
  height: 5rem;
  text-align: center;
  align-items: center;
  font-family: FuturaBT-MediumCondensed;
}
.whyUs__heading_sub {
  display: inline-block;
  margin-top: 1rem;
  font-size: 2.1rem;
  line-height: 1.5;
  color: #2f3020;
  width: 100%;
  justify-content: center;
  font-weight: 549;
}

.whyUs__content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2.1rem;
}
.whyUs-img {
  width: 17rem;
}
.whyUs-img1{
  width: 15rem;
}
.whyUs-img4 {
  width: 20rem;
}

.whyus__Ans {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
}
.whyus__Ans p {
  width: 80%;
  font-size: 1.8rem;
  margin: 3rem 0;
  text-align: center;
}
@media all and (max-width: 992px) {
  div .whyUs__outta > .whyUs__content > .whyus__Ans {
    padding: 0;
  }
}
@media all and (max-width: 849px) {
  div .whyUs__outta > .whyUs__content > .whyus__Ans {
    padding: 0;
    margin: 0 4rem;
  }
}
@media all and (max-width: 609px) {
  div .whyUs__outta > .whyUs__content > .whyus__Ans {
    margin: 0;
  }
}
@media all and (max-width: 450px) {
  .whyus__Ans {
    width: 16rem;
  }
  .whyUs-img {
    width: 12rem;
  }
  .whyUs-img1 {
    width: 14rem;
  }
  .whyus__Ans p {
    font-size: 1.7rem;
    margin: 1.5rem 0;
  }
}

@media all and (max-width: 730px) {
  .whyUs__title > h2 > .whyUs__heading_main {
    font-size: 2rem;
    font-weight: bolder;
    width: 80%;
  }
}
@media all and (max-width: 499px) {
  .h2__heading_main {
    padding: 0;
  }
  .p__heading-top {
    display: contents;
    font-size: 1.9rem;
  }
  .whyUs__heading_sub {
    font-size: 2rem;
  }
}

.deliveryDetail__outta {
  margin: 4rem 2.5rem;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  font-family: "Helvetica", -apple-system, BlinkMacSystemFont;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}
.deliveryDetail__title {
  text-align: center;
  margin: 1rem;
  width: 100%;
  justify-content: center;
  display: flex;
}
.deliveryDetail__title > h2 {
  display: flex;
  margin-bottom: 2rem;
  text-transform: uppercase;
  width: 50%;
  justify-content: center;
  align-self: center;
  background: #E7EDF3;
  border-radius: 1.5rem;
  color: #ffa101;
  /* text-shadow: 3px 2px 3px black; */
  font-size: 3rem;
  font-weight: bolder;
  height: 5rem;
  text-align: center;
  align-items: center;
  font-family: FuturaBT-MediumCondensed;
}
.deliveryDetail__heading_main {
  margin-bottom: 0rem;
  text-transform: uppercase;
}
.deliveryDetail__heading_sub {
  display: inline-block;
  margin-top: 1rem;
  font-size: 2.1rem;
  line-height: 1.5;
  color: #6e6f72;
  width: 55%;
}

.deliveryDetail__content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  border-radius: 0.5rem;
}
.deliveryDetial_description {
  min-width: 19rem;
  width: 60rem;
  color: black;
}
.deliveryDetial_description > h2 > span {
  line-height: inherit;
  word-spacing: 0.39rem;
  padding: 1rem;
  display: block;
}
.deliveryDetial_description > h2 > span:first-child,
.deliveryDetial_description > h2 > span:nth-child(2) {
  font-size: 4rem;
  font-weight: bolder;
  color: white;
  text-shadow: 3px 2px 3px black;
}
.deliveryDetial_description > h2 > span:nth-child(2) {
  color: #ffa101;
  font-size: 7rem;
  text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.7);
}
.deliveryMap {
  padding: 2rem;
}
.deliveryMap_img {
  width: 35rem;
  height: auto;
}
.delivery_FriendlyRemind {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
/* fakebtn {
} */
.onClick_btn {
  position: relative;
  margin: 2.5rem;
  width: 15rem;
  /* border: .1rem solid; */
  height: 6rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5rem;
  background: white;
  box-shadow: 2px 3px 19px rgba(0, 0, 0, 0.7);
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.onClick_btn:hover,
.onClick_btn:active,
.onClick_btn:focus {
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.12);
  /* font-weight: bold; */
  transform: scale(1.1);
  /* color: white; */
  transform: translateY(-8px);
}

/* area price */
.displayN {
  display: none;
  font-size: 2rem;
}

@media all and (max-width: 1039px) {
  .deliveryDetial_description {
    margin: 0;
  }
}

@media all and (max-width: 869px) {
  .deliveryDetial_description {
    width: 25rem;
  }
  .deliveryDetail__content {
    justify-content: space-around;
  }
}
@media all and (max-width: 768px) {
  .deliveryDetial_description {
    width: 52rem;
  }
  .deliveryDetail__content {
    justify-content: space-around;
  }
}
@media all and (max-width: 570px) {
  .deliveryDetail__title > h2 {
    font-size: 2rem;
    width: 50%;
  }
  .deliveryDetial_description {
    width: 52rem;
  }
  .deliveryDetail__content {
    /* justify-content: space-around; */
    text-align: center;
  }
  .deliveryDetial_description > h2 > span:first-child,
  .deliveryDetial_description > h2 > span:nth-child(2) {
    font-size: 4.5rem;
  }
  .deliveryDetial_description > h2 > span {
    font-size: 1.9rem;
}
}

.cutOffTimeDiv__outta {
  margin: 4rem 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.content {
  width: 50%;
}
.cutOffTimeDiv {
  text-align: center;
  margin: 1rem;
  display: inherit;
  justify-content: center;
  flex-direction: column;
}
.followUsDiv {
  text-align: center;
  margin: 1rem;
  display: inherit;
  justify-content: center;
  flex-direction: column;
}
.followUsDiv > social {
  display: inline-flex;
  justify-content: center;
  font-size: 2rem;
}
social > a,
social > button {
  max-width: 2rem;
  margin: 0 1rem;
  color: #31525b;
}
social > a:active, social > a:hover{
  color: #ffa101;
}

.cutOffTimeDiv__heading_main {
  display: block;
  font-size: 2rem;
  text-transform: uppercase;
  color: #ef6c00;
  font-weight: bold;
}
.cutOffTimeDiv__heading_main >h2{
  font-size: 3rem;
    font-weight: bold;
    color: #31525b;
    margin: 0;
}
.Cutoffdiv_mobileVer {
  margin: 2rem 0;
}
.cutOffTimeDiv__heading_main th,
.cutOffTimeDiv__heading_main td {
  border-left-style: groove;
  border-right-style: groove;
}

@media all and (max-width: 1039px) {
  .cutOffTimeDiv_description {
    margin: 0;
  }
}
@media all and (max-width: 999px) {
  .cutOffTimeDiv_description {
    margin: 0 0 4rem 0;
  }
}
@media all and (max-width: 538px) {
  .cutOffTimeDiv__outta {
    flex-wrap: wrap;
  }
}

div .card__style {
  border: none;
}
.card__style {
  width: 22rem;
  height: 28rem;
}
.card__style .card-header {
  border-bottom: 0 white;
  background-color: white;
}
.cardTitle_style {
  margin-top: 2.5rem;
}
.cardTitle_style h2 {
  font-weight: bolder;
  text-transform: uppercase;
}
.img_style {
  width: 20rem;
  height: 20rem;
}

.borderBottom {
  border-bottom: .3rem solid #113067;
}
.borderBottomPlus {
  border-bottom: .9rem solid #fa923f;
}

@media (max-width: 879px) {
  .img_style {
    width: 18rem;
    height: 18rem;
  }
  .card__style {
    width: 20rem;
    height: 26rem;
  }
}

@media (max-width: 799px) { 
  .img_style {
    width: 15rem;
    height: 15rem;
  }
  .card__style {
    width: 18rem;
    height: 23rem;
  }
} 

@media (max-width: 719px) { 
    .img_style {
        width: 10rem;
        height: 10rem;
    }
    .card__style {
        width: 12rem;
        height: 19rem;
    }
  } 
  @media (max-width: 478px) { 
    .img_style {
        width: 13rem;
        height: 13rem;
    }
    .card__style {
        width: 16rem;
        height: 19rem;
    }
    .choiceDisplay ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 39rem;
  }
  .borderBottomPlus {
    border-bottom: .5rem solid #fa923f;
  }
  .borderBottom {
    border-bottom: .3rem solid #113067;
  }
  }  
.Card_link {
  cursor: pointer;
}
div .cardUi_Text {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  width: 15rem;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow {
  overflow: hidden;
}
.card-img-top {
  transform: scale(1);
  transition: transform 0.5s ease-in;
  height: 15rem;
  padding: 1rem;
}

.card-img-top:hover {
  transform: scale(1.2);
}
a div .card-body {
  text-align: start;
  font-size: 15px;
  padding: 0 1.25rem;
  display: inline-block;
}

a .recipeCard_div {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  height: 28rem;
}
.card a:hover {
  text-decoration: none;
}

@media all and (max-width: 599px) {
  .card-img-top {
    transform: none;
    transition: none;
  }
  .card-img-top:hover {
    transform: none;
  }
  div .cardUi_Text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    width: initial;
  }
}

.show_RecipCard_outtaBox{
    display: flex;
    margin: 0 3rem 5rem 3rem;
    justify-content: center;
    flex-wrap: wrap;
 flex-direction: row;
}

.recipeCard__outtaBox{
    width: 18rem;
    margin: 1rem 1rem;
    cursor: pointer !important;
}
.recipeCard__outtaBox:hover{
    box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}
@media all and (max-width: 599px){
    .show_RecipCard_outtaBox{
        margin: 0;
    }
    .recipeCard__outtaBox{
        width: 15rem;
        margin: .5rem .5rem;
    }
}
.choiceDisplay {
  margin: 7.4rem 0 0 0;
  padding: 0px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.choiceDisplay h1 {
  margin: 50px;
  padding-bottom: 20px;
}
.choiceDisplay ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.choiceDisplay__secondary {
  padding: 3rem;
  text-align: center;
}

.choiceDisplay__secondary p {
  width: 100%;
  font-size: 2.5rem;
  font-weight: normal;
  padding-bottom: 2.5rem;
  border-bottom: .2rem solid lightblue;
}

/* @media (max-width: 879px) {
  .choiceDisplay__secondary p {
    font-size: 25px;
    width: 700px;
  }
}

@media (max-width: 768px) {
  .choiceDisplay__secondary p {
    font-size: 25px;
    width: 550px;
  }
}

@media (max-width: 740px) {
  .choiceDisplay__secondary p {
    font-size: 25px;
    width: 350px;
  }
}

@media (max-width: 375px) {
  .choiceDisplay__secondary p {
    font-size: 10px;
    width: 300px;
  }
} */
@media all and (max-width: 719px) {
  .choiceDisplay__secondary p {
    width: 100%;
    font-size: 1.5rem;
    font-weight: normal;
    /* padding-bottom: 25px; */
    border-bottom: 2px solid lightblue;
    padding: 0 2rem 2rem 2rem;
  }
}

.accordion__section{ 
    display: flex;
    flex-direction: column;
}

.accordion{
    background-color: #fafbfc;
    color: #444;
    cursor: pointer;
    padding: 18px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.accordion:hover,
.accordion_active{
    background-color:#FAFBFC;
}

.accordion__title{
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 20px;
}

.accordion__icon{
    transition: transform 0.6s ease;

}

.rotate{
    transform: rotate(90deg);
}

.accordion_content_outta{
    background-color: white;
    overflow: hidden;
    transition: max-height .6s ease; 
}

.accordion__content{
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 14px;
}

@media all and (min-width:375px){
    .recipeCard_changes {
        transform: none;
        transition: none;
        ;
      } 
      
      .recipeCard_changes:hover {
        transform: none;
      }
}
.side-drawer {
  height: 100%;
  background: #fff8e1;
  /* color: white; */
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  max-width: 80rem;
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.25s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.side-drawer.open {
  transform: translateX(0);
}
.side-drawer.close {
  transform: translateX(-100%);
}
.drawer_userDiv {
  /* height: 12%; */
  min-height: 23.1rem;
  margin: 5.1rem 0 0 0;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.drawer_greeting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem;
}
.changeLanguages {
  font-family: "Helvetica";
}
.drawer_userIcon {
  max-width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Helvetica";
  text-decoration: none;
}
div .drawer_userIcon > .drawer_logout {
  width: 9rem;
  height: 4rem;
  color: #31525b;
  background-color: #ffa101;
  font-weight: bold;
  margin-top: 0rem;
  left: -0%;
  position: relative;
  text-align: center;
  border-radius: 0.5rem;
  align-self: center;
  align-items: center;
  align-content: end;
  display: inline-grid;
}
.drawer_logout .text-signIn {
  text-decoration: none;
}
div .drawer_userIcon > a:hover,
div .drawer_userIcon > a:active,
div .drawer_logout:active,
div .drawer_logout:hover {
  text-decoration: none;
}
.drawer_Img {
  width: 10rem;
}
.side-drawer > ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 6rem 0;
}

.side-drawer > li,
.side-drawer > ul > .sideDrawer_link {
  font-weight: 900;
  padding: 0.5rem 0;
  max-width: inherit;
  display: flex;
  justify-content: flex-start;
  /* border-top: .1rem solid black; */
  color: #fae6b1;
  background-color: #31525b;
  border-bottom: 0.1rem solid #b3dee5;
}

.side-drawer > ul > .sideDrawer_link:hover,
.side-drawer > ul > .sideDrawer_link:active {
  color: #31525b;
  background-color: #fae6b1;
  border-bottom: 0.1rem solid #7d8e95;
}
.side-drawer a {
  text-decoration: none;
  font-size: 2rem;
}
.sideDrawer_link > li {
  display: inline-flex;
}
.drawer_Icon_Link {
  width: 2.5rem;
  margin: 0 1rem;
}
.sideDrawer_link li > p {
  margin: 0 1rem;
}
.side-drawer a:hover,
.side-drawer a:active {
  text-decoration: none;
}
.side-drawer .child-content li a {
  padding: 1rem;
  margin: 0.1rem;
}
.sideBar_Home {
  display: none;
}

@media (min-width: 769px) {
  .side-drawer {
    display: none;
  }
}

.backdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.3);
    z-index: 100;
}
.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 35px;
    width: 40px;
    background: transparent;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    background: #BEC9DB;
    opacity: 0.8;
  }
  .toggle-button:focus {
    outline: none;
  }
  .toggle-button__line{
      width: 30px;
      padding: 2.5px;
      background: #113067;
      margin-left: .5rem;
      border-radius: 5px;
  }
  .toggle-button:active, .toggle-button:hover{
    background-color:  #ff8000;
    opacity: 0.7;
  }

  .menuBtn{
    width: 4rem;
    height: 4rem;
    margin: 1rem 1rem 1rem 1.5rem;
  }
.toolbar {
  height: 6.45rem;
  top: 0;
  left: 0;
  width: 100%;
  background: #E7EDF3;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
}
.toolbar_admin {
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
}
.toolbar__nav {
  display: flex;
  height: 100%;
  align-items: center;
  /* padding: 0 1rem; */
  /* color: #ffa101; */
  max-height: 8.5rem;
}

.toolbar__Logo {
  width: 9.5rem;
  margin-left: 2.5rem;
}
.toolbar__Logo:active,
.toolbar__Logo:hover {
  background-color: white;
  border-radius: 0.2rem;
}
.toolbar__logo span {
  /* color: #ff8000; */
}
.toolbar__logo a {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: -3.9px;
  display: -webkit-box;
}
.toolbar__logo p {
  font-size: 30px;
  margin-top: 25px;
}
.spacer-01 {
  /* flex: 1 1; */
}
.spacer {
  flex: 1 1;
}
.toolbar_nav-items {
  align-self: center;
  min-width: 50rem;
}
.toolbar_nav-items .toolbar_nav-items_links {
  list-style: none;
  margin: 0 2rem 0 1.5rem;
  padding: 0;
  display: flex;

}
.toolbar_nav-items > ul {
  display: flex;
  list-style: none;
  margin: 0;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  align-items: center;
}

.toolbar_nav-items a {
  color: #ffa101;
  text-decoration: none;
  font-size: 2.5rem;
  margin: 0 .5rem;
  font-weight: 900;
}
.toolbar__logo a:hover,
.toolbar__logo a:active,
.toolbar_nav-items a:hover,
.toolbar_nav-items a:active {
  color: black;
  text-decoration: none;
}
.toolbar_nav-item {
  align-self: flex-end;
  min-width: 10rem;
}
.toolbar_nav-item > a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  justify-content: center;
  display: flex;
}
.btn-warning:active,
.btn-warning:hover {
  color: black;
}

.footerLink_abt1:active,
.footerLink_abt1:hover {
  background-color: inherit;
}
.time {
  background-color: #f36127;
  height: 2.9rem;
  color: white;
  font-size: 1.9rem;
  font-weight: 600;
  text-align: center;
}

/* second navbar */
header > .nav2 {
  height: 6rem;
  margin: 1rem;
  align-items: flex-end;
  font-family: "Helvetica";
}

.mobile_nav {
  display: inline-flex;
}
.mobile_btn {
  /* flex: 1; */
}

.cart_btn {
  width: 32rem;
  height: 6rem;
  /* margin: 0rem 2rem 0 2rem; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fd7e14;
}
.cart_btn:hover,
.cart_btn:active,
.drawer_Icon_Link:hover,
.drawer_Icon_Link:active {
  background: #7d8e95;
  color: white;
  text-decoration: none;
}
.cart_btn > h5 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 800;
}
.cart_btn_img {
  width: 3.2rem;
}
@media all and (min-width: 769px) {
  .spacer {
    /* display: none;
    flex: 0; */
  }
}
@media (max-width: 768px) {
  .nav2 {
    height: 9rem;
    margin: 1rem;
    position: fixed;
    left: -10px;
    bottom: -10px;
    background-color: white;
    width: 100%;
  }
  .toolbar_nav-items {
    display: none;
  }
  .cart_btn {
    width: 32rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .cart_btn > p {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 800;
  }
  .cart_btn_img {
    width: 3.2rem;
  }
}

@media (min-width: 769px) {
  .nav2 {
    display: none;
  }
  .toolbar__toggle-button {
    display: none;
  }
  .toolbar__logo {
    margin-left: 0;
  }
}

/* below mobile btn width in different screen size */
@media all and (max-width: 689px) {
  .cart_btn {
    width: 29rem;
  }
}
@media all and (max-width: 630px) {
  .cart_btn {
    width: 25rem;
  }
}
@media all and (max-width: 568px) {
  .cart_btn {
    width: 20rem;
  }
}
@media all and (max-width: 493px) {
  .cart_btn {
    width: 18rem;
  }
}
@media all and (max-width: 455px) {
  .cart_btn {
    width: 15rem;
  }
}
@media all and (max-width: 413px) {
  .cart_btn > p {
    font-size: 1.3rem;
    margin: 0;
    font-weight: 700;
  }
  .cart_btn_img {
    width: 3rem;
  }
}
@media all and (max-width: 375px) {
  .cart_btn {
    width: 13rem;
  }
}

.main-footer {
  color: #31525b;
  background: #E7EDF3;
  position: relative;
  width: 100%;
  overflow-x: hidden;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.footer_inner {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.footerLink {
  color: #31525b;
  font-size: 1.9rem;
  font-weight: 900;
}
.footerLink:hover {
  text-decoration: none;
  color: #f36127;
}
.footer_detail_div,
.footer_detail_div h4 {
  width: 25rem;
  margin: 1.5rem 0;
  font-size: 2.5rem;
}
.footer_detail,
.footer_detail > h4 {
  margin: 1.5rem 0;
  font-size: 2.5rem;
  width: 19rem;
}
div .list-unstyled > li {
  font-size: 1.9rem;
  font-weight: bolder;
}
.footer_hastag {
  margin: 1rem 0;
}
div > .footer_hastag > h4 {
  font-size: 3rem;
  background: white;
  text-align: center;
  font-family: "Helvetica";
  font-weight: 800;
  height: 5rem;
  color: #1d3124;
  padding: 0.5rem;
  border-radius: 0.3rem;
}

ul > li > .phoneNo {
  color: #f36127;
  font-weight: 600;
}
.footer_bottom, .footer_bottom > p{
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
}

@media all and (max-width:768px){
  .main-footer {
    height: 27rem;
    margin-bottom: 6rem;
}
}
@media all and (max-width:700px){
  .main-footer {
    height: auto;
}
  .footer_detail_div, .footer_detail {
    width: 100%;
    margin: 1.5rem;
    font-size: 2.5rem;
}
}

.helpCenter_btn__content {
  margin: 30px 50px;
  text-align: left;
}
.helpCenter_btn__content p {
  text-align: left;
  text-align: left;
  font-size: 18px;
}

.innerContent__outta {
  margin: 140px 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.help_title {
  height: 250px;
  width: inherit;
  background-color: #f36127;
  color: white;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
}
.help_title h1 {
    text-align: center;
    margin: 91px 0;
    font-size: 5.5rem;
}
.qa_div {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 20px 0 50px 0;
}
.qa_div h2 {
  margin: 20px 0;
  font-size: 25px;
  
}
@media (max-width: 700px) {
  .innerContent__outta {
    margin: 78px 10px;
  }
}

.delivery__outta {
  margin: 11.2rem 0 0 0;
  padding: 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow-x: hidden;
}

.delivery_header {
  width: 100%;
  height: 39rem;
  background-image: url(/static/media/delivery.887db4be.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.delivery__outta h1 {
  padding: 20px 0;

  font-size: 4.5rem;
  word-spacing: 0.2px;
  text-shadow: 0px 2px 2.5px rgba(0, 0, 0, 0.65);
}
.delivery__outta_secondary {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.delivery__outta_secondary h2 {
  padding: 2rem .5rem;
}
.delivery__outta_secondary h4 {
  padding: 0 .5rem;
}

.delivery__content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 5rem 0;
}
.delivery_detail h4 {
  font-size: 2rem;
  text-shadow: 1px 2px 2px rgba(7, 5, 5, 0.5);
}

.delivery_detail p {
  font-size: 1.3rem;
  text-align: left;
}
.delivery_img {
  width: auto;
  height: 13rem;
  margin: 1.5rem 0;
  border-radius: 50%;
  border: .1rem solid #bdc3c7;
  box-shadow: 2px 6px 7px 1px rgba(0, 0, 0, 0.31);
}
.delivery__content_secondary p {
  font-size: 1.8rem;
  font-weight: 700;
}
.delivery__content_secondary span {
  text-decoration: underline;
}
.delivery__content_secondary small {
  text-decoration: underline;
  font-size: small;
  font-style: italic;
}
.delivery_text_bottom {
  padding: 5rem;
}
.delivery_text_bottom p {
  font-size: 3.5rem;
  text-shadow: 0px 2.5px 1.5px rgb(0 0 0);
  color: #F36127;
}

.aboutUs__outta {
  margin: 11.2rem 0 0 0;
  padding: 0;
  display: block;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
}
.aboutus_header {
  padding: 4rem 0;
  width: inherit;
  background-color: #113067;
  color: white;
}
.aboutUs__outta h1 {
  font-size: 4rem;
  text-align: center;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.75);
}
.aboutUs__outta_secondary {
  display: flex;
  justify-content: center;
}
.aboutUs__inner {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.aboutUs__content_Heading {
  margin: 4rem 0;
  padding: 4rem 0;
  width: 100%;
}
.aboutUs__content_Heading h2{
font-size: 3rem;
color: #f36127;
text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.95);
}
.aboutUs__content_Heading h6 {
  padding: 3rem 15%;
  font-size: 1.7rem;
}
.aboutUs__content_secondary {
  padding: 4rem 0;
  width: 100%;
  background-color: #113067;
  color: white;
  border-radius: .5rem;
}
.aboutUs__content_secondary h3{
  font-size: 3rem;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.75);
}
.aboutUs__content_third {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 3rem 0 5rem 0 ;
}
.abtus_detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.abtUs_img_div{
  margin:1rem 0;
}
.abtUs_img{
    width: auto;
    height: 13rem;
    border-radius: 50%;
    border: .1rem solid #bdc3c7;
    box-shadow: 2px 6px 7px 1px rgba(0,0,0,0.31);
}
.abtus_detail h4{
  font-size: 2rem;
  letter-spacing: 0.1px;color: #f36127;
  text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.95);
  
}
.abtus_detail p{
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.3;
  
}

/* @media all and(min-width:375px){
    .aboutUs__inner p{
      font-size: .5rem;
    }
}  */
.specialOffer__outta {
  margin: 11.2rem 0 0 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow-x: hidden;
}
.specialOffer__header {
  height: 35rem;
  background-image: url(/static/media/specialOfferHeader.af2aa42d.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
}
.specialOffer__header h1 {
  font-size: 4.5rem;
  word-spacing: 0.2px;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}
.specialOffer__header span {
  font-size: 3rem;
  font-style: oblique;
}
.specialOffer__header h2 {
  margin: 2rem 0;
  width: 100%;
  height: 10%;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}

.specialOffer__header small {
  font-style: oblique;
}
.specialOffer__header a {
  background-color: #f36127;
  color: white;
  cursor: pointer;
  width: 20rem;
  height: 5rem;
  margin: 2rem 0;
  border-radius: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  align-self: center;
  box-shadow: -1px 12px 14px 0px rgba(0, 0, 0, 0.7);
}
.specialOffer__header p {
  font-size: 2rem;
  font-weight: bolder;
  margin-top: 1rem;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}
.specialOffer__inner_main {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.specialOffer__inner_main h2 {
  margin: 2rem 0;
  word-spacing: .02rem;
  text-shadow: 1px 1.2px 2px rgba(0, 0, 0, 0.45);
}

.specialOffer__content_first{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 0 5rem 0;
}
.specialOffer__details{
    margin: 1rem .5rem;
    box-shadow: 1px 1.2px 2px rgba(0, 0, 0, 0.45);
}
.specialOffer__detail_img{
    width: 20rem;
}
.specialOffer__details p span{
    font-weight: 900;
    font-size: 1.5rem;
}
.specialOffer__details p {
    font-size: 1.5rem;
}

/* **Below** 
Page: Category 
js file : HomeScreen.js
start */

.shop_outta {
  margin: 6rem 0 0 0;
  display: flex;
  flex-direction: column;
}
/* start .pages_path for pages's link on the top */
.pages_path {
  margin: 0 0 0 2rem;
  display: inline-flex;
  justify-content: start;
  align-items: center;
  font-size: 1.7rem;
}
.pages_path > h4 {
  text-align: center;
  align-self: center;
  /* text-decoration: underline; */
  font-size: 1.7rem;
  margin: 0;
}
.pages_path > h3 {
  margin: 0;
}
.pages_path > a {
  color: #bf3613;
  font-weight: 600;
  letter-spacing: 0rem;
}
.pages_path_links {
  margin: 0 0.5rem;
}
.pages_path_BackToResult {
  display: none;
  margin: 0 0 0 1rem;
  align-items: center;
  font-size: 1.7rem;
  border: 0.09rem solid;
  width: 14rem;
  justify-content: center;
  border-radius: 1.5rem;
  height: 3rem;
  background-color: rgb(253, 126, 20);
  color: #fae6b1;
  font-weight: 600;
}
.pages_path_BackToResult > h3 {
  margin: 0;
  font-weight: 900;
}

.pages_path_BackToResult:hover,
.pages_path_BackToResult:active,
.pages_path_BackToResult > h3:hover,
.pages_path_BackToResult > h3:active {
  border: unset;
  background-color: #31525b;
  color: #b3dee5;
  text-decoration: none;
  font-weight: 500;
}
@media all and (min-width: 769px) {
  .pages_path_BackToResult {
    display: none;
  }
}
@media all and (max-width: 768px) {
  .pages_path {
    display: none;
  }
  .pages_path_BackToResult {
    display: flex;
  }
}
/* end of .pages_path  */

/* start filter's div  */
.filterDiv_btn {
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.filterDiv_btn .btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.3rem;
}
filter1 {
  display: inline-flex;
  max-width: 49rem;
  width: 100%;
  margin: 2rem;
}
filter1 > h1 {
  margin: 0 1rem;
  align-self: center;
  /* letter-spacing: 1.4; */
  font-weight: 600;
  color: #F36138;
}
filter1 > .filter_dropdown {
  margin: 0 1rem;
  align-self: center;
  letter-spacing: 1.4;
  min-width: 18rem;
  font-size: 1.5rem;
  justify-content: space-around;
  letter-spacing: 0.19rem;
  font-weight: 800;
}
.filter_dropdown > .filter_dropdownToggle {
  min-width: 18rem;
  font-size: 2.5rem;
  justify-content: space-around;
  letter-spacing: 0.19rem;
  font-weight: 800;
}
.filter_dropdown > .dropdown-menu {
  min-width: 18rem;
  font-size: 2.5rem;
  background-color: #e0e0e0;
  /* color: white; */
  border-radius: 0.5rem;
}
btnoption {
  display: inline-flex;
}
.filter_icon {
  align-self: center;
  margin-right: 0.5rem;
}
.filter_Btn {
  margin: 2rem 2rem 0 2rem;
  align-self: center;
  letter-spacing: 0rem;
  font-size: 1.2rem;
  font-weight: 500;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.filter_Btn > p {
  margin-bottom: 0;
  margin-left: 0.5rem;
}

/* end of filter_div 
  
  Below: div for showing products */

.show_products_outta {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
  justify-content: center;
  font-family: "Helvetica";
}

.show_products {
  display: flex;
  justify-content: center;
  /* flex-direction: ; */
  align-items: center;
  flex-wrap: wrap;
  width: inherit;
  text-align: left;
}

div > .show_products > .Cart_showProduct {
  position: unset;
  display: flex;
  flex-direction: column;
  width: 19rem;
  background-color: #fff;
  background-clip: border-box;
  border: unset;
  border-radius: 0.9rem;
  /* padding: 1rem; */
  margin: 0.5rem 1rem;
}
.Cart_showProduct:hover {
  box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.38),
    3px 5px 7px 0px rgba(0, 0, 0, 0.2);
}
.product {
  display: flex;
  flex-direction: inherit;
}
.product a:hover,
.product a:active {
  text-decoration: none;
}

/* start 
 .div_hover for hover*/

.div_hover {
  width: inherit;
  height: inherit;
  margin: inherit;
}
.divHover {
  transition: 0.5s ease;
  opacity: 0;
  position: relative;
  /* top: 50%;
  left: 50%; */
  transform: translate(0rem, -3rem);
  -ms-transform: translate(-50%, -50%);
  width: inherit;
}
.hoverInner_btn {
  max-width: inherit;
  display: flex;
  justify-content: space-between;
}
.div_hover:hover .hoverImg {
  opacity: 0.79;
}
.div_hover:hover .divHover {
  opacity: 1;
}
.divHover > .cartProduct_detailBtn {
  -webkit-appearance: none;
  -webkit-border-radius: 3rem;
}
div .cartProduct_detailBtn {
  /* color: #fff; */
  text-decoration: none;
  background-color: white;
  font-size: 1.5rem;
  border-radius: 0.5rem;
  height: 3.1rem;
  /* ios must add below or edit  */
  -webkit-appearance: none;
  -webkit-border-radius: 0.5rem;
}
div .cartProduct_detailBtn:active,
div .cartProduct_detailBtn:hover {
  color: #fff;
}

/* Card top img with a tag */
.productLink_1 {
  width: inherit;
  height: 15rem;
  margin: auto;
}

div .CartProduct_cardBody {
  flex: none;
  padding: 0;
  display: block;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  min-height: 10rem;
  width: inherit;
}

div .product-price {
  align-self: center;
}
div .Product_addCartBtn {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem;
  border: none;
  width: 100%;
  font-size: 1.4rem;

  /* ios must add below or edit  */
  -webkit-appearance: none;
  -webkit-border-radius: 0.5rem;
}

div .homeScreen_product-name {
  margin-bottom: 0.75rem;
  width: 18rem;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.product-img {
  width: inherit;
  height: inherit;
  margin: inherit;
  border-radius: 0.9rem;
}
.preference_outtadiv {
  font-size: 1.5rem;
  padding: 0 0.5rem;
}

div > .rw-placeholder {
  color: #31525b;
  font-size: 2rem;
}
.rw-select-list-input {
  margin: 0 0.5rem 0 0;
}
/* start @media */
@media all and (max-width: 768px) {
  filter1 {
    margin: 1rem 2rem;
  }
  div > .show_products > .Cart_showProduct {
    width: 16rem;
    margin: 0.5rem 0.9rem;
  }
  .divHover {
    transition: none;
    opacity: 1;
  }
}

/* sort and category hover */
.sortHover,
.categoryHover {
  position: relative;
}
.sortHover:hover .hover__no-hover,
.categoryHover:hover .hover__no-hover {
  opacity: 0;
}
.sortHover:hover .hover__hover,
.categoryHover:hover .hover__hover {
  opacity: 1;
}
.sortHover .hover__hover,
.categoryHover .hover__hover {
  position: absolute;
  top: 0;
  opacity: 0;
}
.sortHover .hover__no-hover,
.categoryHover .hover__no-hover {
  opacity: 1;
}
.sortSelect,
.categorySelect {
  position: absolute;
  z-index: 20;
  font-size: 2rem;
}
/* filter_search_btn {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 5px 0px;
    padding: 0px;
    width: 300px;
    text-align: center;
  } */
/* .filterInput_label {
  font-size: 2rem;
  margin: 1rem 0 0rem 0;
}
.category_div ul li {
  list-style: none;
  display: table;
}
.filter_inputFeild input {
  margin: 0 1rem 0 0;
} */

.detailATag {
  width: 40rem;
  height: 6rem;
  text-align: center;
  font-weight: 600;
  font-size: 2.5rem;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .detailATag {
    width: 45%;
    height: 5rem;font-size: 2rem;
  }
}

@media (max-width: 409px) {
  .detailATag {
    width: 45%;
    height: 4rem;
    font-size: 1.5rem;
  }
  .borderBottom {
    border-bottom: 0.2rem solid #113067;
  }
}

.productDetail_bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  min-width: 18rem;
}

.productDetail_bottom > ul {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 5rem;
  margin: 0 0 3rem 0;
}
.ServicesOptions_details {
  display: flex;
    justify-content: center;
    flex-direction: inherit;
    margin: 0 4rem;
    padding: 1rem 4rem;
    text-align: center;
    min-width: 40rem;
    max-width: 80rem;
    height: 100%;font-family: "Helvetica";
}
.ServicesOptions_content_main{
  display: flex;
  flex-direction: inherit;
  justify-content: inherit;
  margin: 2rem 0;
}
.ServicesOptions_content_sub{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: start;
  margin: 2rem;
}
.content_sub_qs{
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
text-align: start;
}
.content_sub_ps{
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  .productDetail_bottom {
    width: inherit;
    margin: 2rem 0;
    min-width: unset;
  }
}

/* Product Details */
.productDetail-outta {
  margin: 78px 0;
}
.productDetail_content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0rem;
  width: 100%;
}
.productDetail_top {
  width: 50rem;
  height: auto;
  max-height: 50%;
  min-width: 37rem;
  margin: 2rem 4rem;
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 67vh;
}

.productDetail_top_right {
  width: 35rem;
  height: auto;
  max-width: 50%;
  margin: 2rem;
}

/* shareDiv*/
.shareDiv {
  display: flex;
  margin: 2rem;
}

/* end of shareDiv  */

.details-image img {
  max-width: 60rem;
  width: 100%;
}

.productDetail_top_right > ul,
.details-action ul {
  list-style-type: none;
  padding: 0;
}
.productDetail_top_right > ul > li,
.details-action li {
  margin-bottom: 1rem;
}
.productAct_btnGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: inherit;
  height: 14rem;
}
.details-action {
}
.details-action ul li:last-child {
  display: flex;
  flex-direction: column;
}
.btnGroup_counter {
  display: flex;
  justify-content: space-between;
  width: inherit;
  border-radius: 0.5rem;
  background-color: #fff;
  align-items: center;
  border: unset;
}
ul > li > .productAct_btnGroup > .btnGroup_counter > .productAct_btn {
  width: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 2rem;
  height: 3rem;
  background: #ff9100;
  border: unset;
}
ul > li > .productAct_btnGroup > .btnGroup_counter > .productAct_btn:active {
  border: none;
}
ul > li > .productAct_btnGroup > .btnGroup_counter > .productAct_btn > p {
  margin: 0;
}

.btnGroup_counter > h1 {
  margin: 0;
}
.productAct_btnGroup > button > h1 {
  margin: 0;
  font-weight: 600;
}
.button {
  border-radius: 0.5rem;
  cursor: pointer;
}
.button:hover {
  /*border: 0.1rem #404040 solid;*/
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
}
.button:active{

  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}
.button.primary {
  background-color: #f18901;
}
.button.secondary {
  background-color: #505090;
}
.button.primary:disabled,
.button.secondary:disabled {
  background-color: #dddddd;
}
.fb {
  background-color: #3a5a97;
  color: white;
}
.fb img {
  vertical-align: bottom;
}
.text-center {
  text-align: center;
}
@media all and (min-width: 769px) {
  .productDetail_top {
    width: 37%;
    margin: 2rem 0.5rem;
  }
}
@media all and (max-width: 565px) {
  .productDetail_content {
    display: inline-block;
    margin: 1rem;
  }
  .productDetail_top {
    width: auto;
    margin: 0;
    min-width: unset;
  }
  .productDetail_content > .details-action {
    padding: 0 1rem; 
    width: 80%;
    max-width: none;
    margin: auto;
  }
  .shareDiv {
    margin: 2rem 0;
  }
}

.cartDiv_outta {
  margin: 6.2rem 0 0 0;
  padding: 47px 20px;
    display: flex;
    flex-direction: column;
    /* background-color: #f0f0f0; */
  }
  .cartDiv_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .cart-list {
    display: flex;
    flex-direction: column;
  }
  
  .cart-list__Title {
    text-align: center;
  }
div .cartScreen_img{
  width: 12.5rem;
}
div .cartScreen_title_link{
  font-size: 2.3rem;
    text-align: center;
    font-weight: 600;
    width: 12.5rem;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  .cart-action-outta {
    background-color: #fafafa;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    flex-direction: column;

  }
  /* .cart-action-outta .button {
    background-color: #f36127;
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.35);
    text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
  } */
  .cartAct_inner {
    width: inherit;
    height: inherit;
    font-size: 1.7rem;
  }
  .cartAct_inner_first {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .cartAct_inner_first select{
    font-size: 1.7rem;
  }
  
  .cart_servey_delivery {
    width: 45%;
  }
  .cart_servey_people {
    width: 45%;
    margin: 0 0.5rem;
  }
  .cartAct_inner_second{
    margin: 2rem 0;
  }
  .cartAct_inner_second{
    margin: 2rem 0;
  }
  .cartAct_inner_second .form-check-input {
    position: absolute;
    margin: .3rem 1.5rem 0 -1rem;
  }
  .cartAct_inner_second .form-check-label {
    margin: .3rem 3rem 0 1rem;
  }
  
  @media all and (max-width: 722px) {
    .cartAct_inner_first {
      display: inherit;
    }
  }
  
.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 15px;
    outline: none;
    padding: 20px;
    width: 350px;
    height: 400px;
    background-color: #f36127;
  }
.mymodal_title{
    width: 300px;
    height: 50px;
    text-align: center;
    top: 50%;
    left: 50%;
}
.mymodal_title h1{
    margin: 20px 0;
    letter-spacing: 1.4px;
}


.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}
  
.ReactModal__Body--open {
    overflow: hidden;
}
  
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}
  
.ReactModal__Overlay--after-open {
    opacity: 1;
}
  
.ReactModal__Overlay--before-close {
    opacity: 0;
}
.formDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5% 5%;
  max-width: -webkit-fill-available;
  min-width: auto;
  font-family: "Helvetica";
}
.signInform {
  border: 1px solid #ededed;
  background-color: white;
  display: inline-flex;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 1rem;
}
div > .formDiv > .signInform > .button,
div > .formDiv > .signInform > p {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 3rem;
  border-radius: 5px;
  display: inline-block;
  border: none;
  width: unset;
  margin: 0;
  font-size: 1.5rem;
  
}
.myCart_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: -webkit-fill-available;
  min-width: auto;
  /* background-color: #f8f8fa; */
  margin: 2rem 0;
}
.myCart_orders,
div > .myCart_orders > .myCart_cardDiv {
  display: inherit;
  flex-direction: flex-start;
  width: 100%;
}
/* whole dic for customer's order */
div > .myCart_card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.myCart_DelBtn {
  border: unset;
  border-radius: 3rem;
}
 .myCart_DelBtn:hover{
  /* opacity: .3; */
}
.myCart_DelBtn:active{
  background-color: black;
  /* opacity: none; */
}

.myCart_pfl {
  width: 6rem;
  height:6rem;
}
.myCart_image {
  border-radius: 1rem 1rem;
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
/* start myCartOrder_table */
div > .myCart_cardDiv > .myCartOrder_table {
  border-collapse: inherit;
  border: none;
  margin: 1rem 0;
  display: table;
}

tbody {
  display: contents;
  justify-content: start;
  text-align: start;
}

.td_productInfo {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 11rem;
  flex-flow: row;
  align-items: center;
}
.td_productInfo > h4 {
  align-self: center;
}
div > .myCartOrder_table > tbody > tr > td,
div > .myCartOrder_table > thead > tr > th,
div > .myCartOrder_table > tbody > tr > th {
  padding: 0.75rem;
  vertical-align: middle;
  text-align: center;
  border: none;
}
Table > thead > tr > th,
Table > tbody > tr > th {
  background-color: #b3dee5;
  /* background-color: #fff3e0; */
  vertical-align: middle;
  text-align: center;
  border: none;
}
Table > tbody > tr > td,
Table > tbody > tr:nth-child(odd) {
  background-color: white;
  border-bottom: 0.1rem solid #f0f0f0;
}

div > .table-bordered td,
.table-bordered th {
  border: none;
}
.formQs_btn {
  margin: 1.5rem 0;
}

/* start counter Group  */
.myCart_CounterGroup {
  display: flex;
  justify-content: space-between;
  width: inherit;
  border-radius: 1rem;
  align-items: center;
  border: unset;
}
.myCart_Counter {
  width: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 2rem;
  height: 2rem;
  background: #ff9100;
}
.myCart_Counter:active {
  border: none;
}

.myCart_Counter:hover,
.button:hover {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
}

.myCart_Counter {
  background: #ffd180;
  display: inline-flex;
  justify-content: center;

  margin: 0;
  width: 3rem;
  height: 3rem;
  align-items: center;
  border: unset;
  border-radius: 1rem;
}
.myCart_Counter > p {
  width: inherit;
  height: inherit;
  font-size: 2.3rem;
}
.myCart_CounterGroup > p {
  width: inherit;
  height: inherit;
  font-size: 2.5rem;
  margin: 0;
}
/* start myCart_formSub */
.myCart_formSub {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap-reverse;
}

.myCart_orderInfo,
.myCart_orderInfo_secondary {
  width: 100%;
  max-width: 47.9%;
  margin: 1rem;
}

/* start myCart_formSub */
.formQs {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: inherit;
  height: inherit;
}
.formQs_1 {
  display: inline-flex;
  justify-content: space-between;
  width: inherit;
  flex-direction: row;
}
.formQs_2 {
  display: flex;
  justify-content: space-between;
  width: inherit;
  flex-direction: column;
  margin: 1rem 0;
}
.formQs_span {
  margin: 0.9rem 0;
}
.formQs_SR {
  display: flex;
  justify-content: flex-start;
  width: inherit;
  flex-direction: column;
}
.formQs_3 {
  display: flex;
  flex-direction: column;
  width: inherit;
  border-radius: 0.5rem;
  list-style: none;
}
.formQs_3 li {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
@media all and (max-width:1075px){
  .myCart_orderInfo,
  .myCart_orderInfo_secondary {
    width: 100%;
    max-width: unset;
  }

}

@media all and (max-width: 753px) {
  .tb_mobile {
    display: none;
  }
  .td_productInfo {
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 14rem;
  }
  div > .myCart_cardDiv > .myCartOrder_table {
    border-collapse: collapse;
  }

  .myCart_CounterGroup > p {
    width: inherit;
    height: inherit;
    font-size: 1.8rem;
  }
  td > .myCart_CounterGroup > .myCart_Counter {
    background: white;
    width: 2.5rem;
    height: 2.3rem;
    border: 0.2rem solid #ff9100;
  }
  td > .myCart_CounterGroup > .myCart_Counter > p {
    width: inherit;
    height: inherit;
    font-size: 1.8rem;
  }
}

.PayStep2_form__outta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 3rem 2rem;
  /* background-color: #f8f8fa; */font-family: "Helvetica";
}

.reviewCart_div {
  width: 100%;
  text-align: center;
  /* height: 11rem; */
  background: white;
  padding: 3rem;
  border-radius: 1rem;
  margin: 2.5rem 0;
}
.subTotal_div {
  width: 100%;
  text-align: right;
  /* height: 11rem; */
  background: white;
  padding: 0 1rem;
  border-radius: 1rem;
  margin: 2.5rem 0;
}
.PayStep2_form__inner {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.cardDiv_payScreen{
    width: 100%;
    max-width: 47.9%;
    margin: 1rem;
    font-size: 1.49rem;
}
Form > .form-group>.formQs_checkBox> .form-check-label{
    margin-left: 1rem;
}
Form > .form-group > input{
    font-size: 1.7rem;
}
.PayStep2_form__outta > .back-to-result >.pages_path_BackToResult {
  display: flex;
  text-align: center;
    border-radius: 3rem;
    width: 20rem;
    height: 5rem;
}
div > .formQs_2 > Form > .form-group > select{
 margin: 0 0 .4rem 0 ;
}
.PaidOption{
  height: 17rem;
}

@media all and (max-width: 991px){
  .PayStep2_form__outta {
    padding: 0;
    padding: initial;
}
}

@media all and (max-width: 950px){
  .cardDiv_payScreen {
    margin: 0;
    margin: initial;
}
}

@media all and (max-width: 768px){
  .cardDiv_payScreen {
    margin: 1rem;
    max-width: unset;
}
}
/* Variables */
:root {
  --gray-offset: rgba(0, 0, 0, 0.03);
  --gray-border: rgba(0, 0, 0, 0.15);
  --gray-light: rgba(0, 0, 0, 0.4);
  --gray-mid: rgba(0, 0, 0, 0.7);
  --gray-dark: rgba(0, 0, 0, 0.9);
  --body-color: var(--gray-mid);
  --headline-color: var(--gray-dark);
  --accent-color: #0066f0;
  --body-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  --radius: 6px;
  --logo-image: url("https://storage.googleapis.com/stripe-sample-images/KAVHOLM.svg");
  --form-width: 343px;
}

.sr-combo-inputs {
  margin: 20px 0;
}

.sr-input {
  font-size: 16px;
}

.sr-card-element {
  padding-top: 12px;
}

.btn-stripe {
  font-size: 16px;
  width: 100%;
  background: #0066f0;
  background: var(--accent-color);
  border-radius: 6px;
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
.btn-stripe:hover {
  -webkit-filter: contrast(115%);
          filter: contrast(115%);
}
.btn-stripe:active {
  transform: translateY(0px) scale(0.98);
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}
.btn-stripe:disabled {
  opacity: 0.5;
  cursor: none;
}

.sr-text {
  max-width: 825px;
  margin: 0 auto;
  font-size: 14px;
  background: white;
  color: #6a7c94;
}

.PayStep2_form__outta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 3rem 2rem;
  /* background-color: #f8f8fa; */font-family: "Helvetica";
}

.reviewCart_div {
  width: 100%;
  text-align: center;
  /* height: 11rem; */
  background: white;
  padding: 3rem;
  border-radius: 1rem;
  margin: 2.5rem 0;
}
.subTotal_div {
  width: 100%;
  text-align: right;
  /* height: 11rem; */
  background: white;
  padding: 0 1rem;
  border-radius: 1rem;
  margin: 2.5rem 0;
}
.PayStep2_form__inner {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.cardDiv_payScreen{
    width: 100%;
    max-width: 47.9%;
    margin: 1rem;
    font-size: 1.49rem;
}
Form > .form-group>.formQs_checkBox> .form-check-label{
    margin-left: 1rem;
}
Form > .form-group > input{
    font-size: 1.7rem;
}
.PayStep2_form__outta > .back-to-result >.pages_path_BackToResult {
  display: flex;
  text-align: center;
    border-radius: 3rem;
    width: 20rem;
    height: 5rem;
}
.PayStep2_form__outta > .back-to-shop {
  margin: 1rem;
  display: flex;
  justify-content: center;
}
.PayStep2_form__outta > .back-to-shop >.pages_path_BackToShop {
  display: flex;
  text-align: center;
    border-radius: 3rem;
    width: 20rem;
    height: 5rem;
}
div > .formQs_2 > Form > .form-group > select{
 margin: 0 0 .4rem 0 ;
}
.PaidOption{
  height: 17rem;
}

@media all and (max-width: 991px){
  .PayStep2_form__outta {
    padding: 0;
    padding: initial;
}
}

@media all and (max-width: 950px){
  .cardDiv_payScreen {
    margin: 0;
    margin: initial;
}
}

@media all and (max-width: 768px){
  .cardDiv_payScreen {
    margin: 1rem;
    max-width: unset;
}
}
.member_shipment_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin: 2% 5%;
    max-width: -webkit-fill-available;
    min-width: auto;
    font-family: "Helvetica";
}

.member_shipment_column_1,
.member_shipment_column_2 {
  width: 100%;
  max-width: 47.9%;
  margin: 1rem;
}

@media all and (max-width:1075px) {
    .member_shipment_wrapper {
        flex-direction: column;
    }
    .member_shipment_column_1,
    .member_shipment_column_2 {
      width: 100%;
      max-width: unset;
    }
}

.member_shipment_card {
    position: inherit;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.member_shipment_card-body {
    min-height: 1px;
    padding: 1.25rem;
}

.member_shipment_card-body .member_shipment_card-body_children {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

.member_shipment_card-body button {
    align-items: center;
}
.member_payment_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2% 5%;
    max-width: -webkit-fill-available;
    min-width: auto;
    font-family: "Helvetica";
}

.member_payment_card {
    position: inherit;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(87, 64, 64, 0.125);
    border-radius: .25rem;
    margin: 1rem;
}

.member_payment_card-body {
    min-height: 1px;
    padding: 1.25rem;
}

.member_payment_card-body .member_payment_card-body_children {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

.member_payment_card-body button {
    align-items: center;
}
.placeorder {
    display: flex;
    flex-grow: wrap;
    padding: 1rem;
    justify-content: space-between;
    font-size: 1.6rem;
    font-family: "Helvetica";
  }
.placeorder-info {
    flex: 3 1 60rem;
  }
.placeorder-action {
    border: 0.1rem #c0c0c0;
    border-radius: 0.5rem;
    background-color: #fcfcfc;
    padding: 2rem 3rem;
    font-size: 2rem;
    font-family: "Helvetica";
  }
.placeorder-info > div {
    border: 0.1rem #c0c0c0;
    border-radius: 0.5rem;
    background-color: #fcfcfc;
    padding: 1rem;
    margin: 1rem;
  }
.placeorder-info > div :first-child {
    margin-top: 0;
  }
.placeorder-action > ul {
    padding: 0;
    list-style-type: none;
  }
.placeorder-action > ul > li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
.placeorder-action > ul > li:last-child {
    font-size: 2rem;
    color: #c04000;
    font-weight: bold;
  }
.placeorder-actions-payment > div {
    width: 100%;
  }
.placeorder-action > form {
    font-size: 1.6rem;
  }
.orderScreen__div {
  margin: 10rem;
}
.orderScreen_placeorder {
  display: flex;
  flex-grow: wrap;
  padding: 1rem;
  justify-content: space-between;
  font-size: 1.6rem;
}
.orderScreen_placeorder-info {
  flex: 3 1 60rem;
}

.orderScreen_placeorder-info > div {
  border: 0.1rem #c0c0c0;
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 1rem;
  margin: 1rem;
}
.orderScreen_placeorder-info > div :first-child {
  margin-top: 0;
}
.orderScreen_placeorder-action {
  border: 0.1rem #c0c0c0;
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 2rem 3rem;
  font-size: 2rem;
}

.orderScreen_placeorder-action > ul {
  padding: 0;
  list-style-type: none;
}
.orderScreen_placeorder-action > ul > li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.orderScreen_placeorder-action > ul > li:last-child {
  font-size: 2rem;
  color: #c04000;
  font-weight: bold;
}
.orderScreen_placeorder-actions-payment > div {
  width: 100%;
}
.card-img-top {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.orderScreen_textarea {
  width: 100%;
}
.orderScreen_map {
  height: 70vh;
  width: 100%;
}
.infoBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2979FF;
  border-radius: 4px 4px 0 0;
  height: 60px;
  width: 100%;
}

.leftInnerContainer {
  flex: 0.5 1;
  display: flex;
  align-items: center;
  margin-left: 5%;
  color: white;
}

.rightInnerContainer {
  display: flex;
  flex: 0.5 1;
  justify-content: flex-end;
  margin-right: 5%;
}

.onlineIcon {
  margin-right: 5%;
}

.form {
  display: flex;
  border-top: 2px solid #D3D3D3;
}

.input {
  border: none;
  border-radius: 0;
  padding: 5%;
  width: 80%;
  font-size: 1.2em;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.sendButton {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #2979FF;
  padding: 20px;
  display: inline-block;
  border: none;
  width: 20%;
}
.messages {
  padding: 5% 0;
  overflow: auto;
  flex: auto;
}
.messageBox {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #2979FF;
}

.backgroundLight {
  background: #F3F3F3;
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  color: white;
  height: 60%;
  justify-content: space-between;
}

.activeContainer {
  display: flex;
  align-items: center;
  margin-bottom: 50%;
}

.activeItem {
  display: flex;
  align-items: center;
}

.activeContainer img {
  padding-left: 10px;
}

.textContainer h1 {
  margin-bottom: 0px;
}

@media (min-width: 320px) and (max-width: 1200px) {
  .textContainer {
    display: none;
  }
}
.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1A1A1D;
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 8px;
  height: 60%;
  width: 35%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .outerContainer {
    height: 100%;
  }

  .chat-container {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 480px) and (max-width: 1200px) {
  .chat-container {
    width: 60%;
  }
}

.location {
    margin: 12.3rem ;
}
.location_map {
    height: 500px;
    width: 100%;
}
.table_export {
        margin: 12.3rem 0 0 0;
}
.dashboard_outta {
  margin-top: 5rem;
}

.dashboard_container{
    margin: 13.3rem ;
    overflow: hidden;
    width: 80%;
    text-align: center;
  }

.dashboard_setting,
.dashboard_dropdownlist{
  margin: 2vh 0;
}

/* Modal */
.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-110%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  outline: none;
  padding: 20px;
  width: 40%;
  height: 80%;
  background-color: #fff;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
