.specialMenu_outta {
  margin: 0 1rem;
}
.specialMenu_content {
  display: flex;
  justify-content: center;
}
.specialMenu_content > h2 {
  font-size: 3rem;
  font-weight: bolder;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 5rem;
  margin: 0;
  align-items: center;
  background: #E7EDF3;
  border-radius: .5rem;
  color: #ffa101;
}

.specialMenu_Carousel {
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0 1.5rem;
}
.showLink_mobile {
  /* display: none; */
}
div .specialMenu_Carousel > ul {
  /* width: 100%; */
  margin: 2rem 0;
  justify-content: center;
}
li .specialmenu_item {
  border-radius: 1rem;
  border: 0rem;
}
div .specialMenu_Carousel > ul > .specialMenu_itemEach {
  max-width: 30rem;
  margin: 0 0.5rem;
  border-radius: 1rem;
}
.specialMenu_itemEach::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.38),
    3px 5px 7px 0px rgba(0, 0, 0, 0.2); */
  z-index: -1;
  opacity: 0;
  transform: scale(0.75);
  transition: all 0.2s ease-in-out;
  will-change: transform;
  border-radius: 1rem;
}
.specialMenu_itemEach:hover:before {
  opacity: 1;
  transform: none;
}

.pfl_special {
  height: 35vh;
}
.pflimage_special {
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 1rem;
}
.pflhover_special {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: #fae6b1;
  width: 90%;
  height: auto;
  /* text-align: center; */
  background-color: wheat;
  border-radius: 1rem;
}
.pfl_special:hover .pflimage_special {
  opacity: 0.89;
  box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.38),
    3px 5px 7px 0px rgba(0, 0, 0, 0.2);
}
.pfl_special:hover .pflhover_special {
  opacity: 1;
  box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.38),
    3px 5px 7px 0px rgba(0, 0, 0, 0.2);
}
div .pflhover_special .pflhover_special_btn {
  color: #344648;
  /* background-color: black; */
  font-size: 2.2rem;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  opacity: 0.8;
  font-weight: 800;
  display: flex;
  justify-content: center;
}

.card-body {
  display: none;
}

@media all and (max-width: 1290px) {
  .pfl_special {
    /* height: 23vh; */
  }
  .pflimage_special {
    opacity: 1;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s ease;
    backface-visibility: hidden;
    border-radius: 1rem;
  }

  .pfl_special:hover .pflimage_special {
    opacity: 0.95;
  }
  .pfl_special:hover .pflhover_special {
    opacity: 1;
  }
  .card-body {
    display: flex;
    padding: none;
  }
  .card-body .specalMenu_each_type_btn {
    width: 22rem;
    height: 4rem;
    font-size: 2.25rem;
    line-height: 1.2;

    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }
  div .specialMenu_Carousel > ul {
    width: unset;
  }
}
@media all and (max-width:730px){
  .specialMenu_content > h2 {
    font-size: 2rem;
    font-weight: bolder;
    width: 80%;}
}

@media all and (max-width: 471px) {
  div .specialMenu_Carousel > ul {
    width: unset;
  }
  .card-body {
    padding: 0 1rem;
  }
  .card-body .speicalMenu_each_type_btn {
    width: 22rem;
    height: 4rem;
    font-size: 1.9rem;
    line-height: 2.2;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }
  div .specialMenu_Carousel > ul > .specialMenu_itemEach {
    max-width: unset;
    margin: 3rem;
  }
  .pflimage_special {
    width: 16rem;
    height: 16rem;
  }
  div .specialMenu_Carousel > ul {
    margin: 0;
  }
  .pfl_special {
    height: 18vh;
  }
  .pflhover_special {
    left: 58%;
}
}