.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 15px;
    outline: none;
    padding: 20px;
    width: 350px;
    height: 400px;
    background-color: #f36127;
  }
.mymodal_title{
    width: 300px;
    height: 50px;
    text-align: center;
    top: 50%;
    left: 50%;
}
.mymodal_title h1{
    margin: 20px 0;
    letter-spacing: 1.4px;
}


.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}
  
.ReactModal__Body--open {
    overflow: hidden;
}
  
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}
  
.ReactModal__Overlay--after-open {
    opacity: 1;
}
  
.ReactModal__Overlay--before-close {
    opacity: 0;
}