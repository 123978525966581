.Card_link {
  cursor: pointer;
}
div .cardUi_Text {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  width: 15rem;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow {
  overflow: hidden;
}
.card-img-top {
  transform: scale(1);
  transition: transform 0.5s ease-in;
  height: 15rem;
  padding: 1rem;
}

.card-img-top:hover {
  transform: scale(1.2);
}
a div .card-body {
  text-align: start;
  font-size: 15px;
  padding: 0 1.25rem;
  display: inline-block;
}

a .recipeCard_div {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  height: 28rem;
}
.card a:hover {
  text-decoration: none;
}

@media all and (max-width: 599px) {
  .card-img-top {
    transform: none;
    transition: none;
  }
  .card-img-top:hover {
    transform: none;
  }
  div .cardUi_Text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: initial;
  }
}
