.placeorder {
    display: flex;
    flex-grow: wrap;
    padding: 1rem;
    justify-content: space-between;
    font-size: 1.6rem;
    font-family: "Helvetica";
  }
.placeorder-info {
    flex: 3 1 60rem;
  }
.placeorder-action {
    border: 0.1rem #c0c0c0;
    border-radius: 0.5rem;
    background-color: #fcfcfc;
    padding: 2rem 3rem;
    font-size: 2rem;
    font-family: "Helvetica";
  }
.placeorder-info > div {
    border: 0.1rem #c0c0c0;
    border-radius: 0.5rem;
    background-color: #fcfcfc;
    padding: 1rem;
    margin: 1rem;
  }
.placeorder-info > div :first-child {
    margin-top: 0;
  }
.placeorder-action > ul {
    padding: 0;
    list-style-type: none;
  }
.placeorder-action > ul > li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
.placeorder-action > ul > li:last-child {
    font-size: 2rem;
    color: #c04000;
    font-weight: bold;
  }
.placeorder-actions-payment > div {
    width: 100%;
  }
.placeorder-action > form {
    font-size: 1.6rem;
  }