.dashboard_outta {
  margin-top: 5rem;
}

.dashboard_container{
    margin: 13.3rem ;
    overflow: hidden;
    width: 80%;
    text-align: center;
  }

.dashboard_setting,
.dashboard_dropdownlist{
  margin: 2vh 0;
}

/* Modal */
.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-110%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  outline: none;
  padding: 20px;
  width: 40%;
  height: 80%;
  background-color: #fff;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}