.choiceDisplay {
  margin: 7.4rem 0 0 0;
  padding: 0px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.choiceDisplay h1 {
  margin: 50px;
  padding-bottom: 20px;
}
.choiceDisplay ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.choiceDisplay__secondary {
  padding: 3rem;
  text-align: center;
}

.choiceDisplay__secondary p {
  width: 100%;
  font-size: 2.5rem;
  font-weight: normal;
  padding-bottom: 2.5rem;
  border-bottom: .2rem solid lightblue;
}

/* @media (max-width: 879px) {
  .choiceDisplay__secondary p {
    font-size: 25px;
    width: 700px;
  }
}

@media (max-width: 768px) {
  .choiceDisplay__secondary p {
    font-size: 25px;
    width: 550px;
  }
}

@media (max-width: 740px) {
  .choiceDisplay__secondary p {
    font-size: 25px;
    width: 350px;
  }
}

@media (max-width: 375px) {
  .choiceDisplay__secondary p {
    font-size: 10px;
    width: 300px;
  }
} */
@media all and (max-width: 719px) {
  .choiceDisplay__secondary p {
    width: 100%;
    font-size: 1.5rem;
    font-weight: normal;
    /* padding-bottom: 25px; */
    border-bottom: 2px solid lightblue;
    padding: 0 2rem 2rem 2rem;
  }
}
